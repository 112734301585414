@mixin japanese {
  font-family: "Noto Serif JP", serif;
}
.header {
  display: flex;
  position: relative;
  background: url(./static/hero.jpg) no-repeat;
  background-position-x: center;
  background-position-y: 0%;
  background-size: cover;
  width: 100%;
  max-width: 700px;
  height: 100vh;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0 auto;
  &___titlewrap {
    position: relative;
    left: 2rem;
    bottom: 3rem;
    &___title {
      color: #fff;
      font-size: 1.8rem;
      font-family: "STIX Two Text", serif;
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.6));
    }
    &___subtitle {
      color: #fff;
      font-size: 0.8rem;
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.6));
      @include japanese();
    }
  }
}
.section {
  width: 100%;
  max-width: 700px;
  position: relative;
  padding: 1.5rem 1.5rem 18rem 1.5rem;
  margin: 0 auto;
  background: url(./static/footer.jpg) no-repeat;
  background-position-y: bottom;
  background-size: 100%;
  &___article {
    margin: 1rem 0 3rem 0;
    &___date {
      @include japanese();
      font-size: 0.6rem;
      color: #999;
    }
    &___title {
      @include japanese();
      font-size: 1.1rem;
      font-weight: bold;
      margin: 0.3rem 0 1rem 0;
    }
    & p {
      @include japanese();
      font-size: 1rem;
      line-height: 160%;
      color: #555;
      & img {
        width: 100%;
        margin: 1rem 0;
      }
      & iframe {
        width: 100%;
        margin: 1rem 0;
      }
    }
  }
}
